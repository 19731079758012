import React from 'react'
import css from './styles.module.scss'

function GithubButton({
  user = 'strapi',
  repo = 'strapi',
  githubStars,
  useDark,
  isLaunchWeek
}) {
  return (
    <div className={css.container}>
      <a href={`https://github.com/${user}/${repo}`} target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          width="24"
          height="24"
          fill={useDark && !isLaunchWeek? '#32324d' : isLaunchWeek ? "#C0C0CF" : '#FFFFFF'} // White icon color
          style={{ marginRight: '8px' }}
        >
          <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.97 0-.88.31-1.59.82-2.15-.08-.2-.36-1.01.08-2.1 0 0 .67-.21 2.2.82a7.63 7.63 0 012.01-.27c.68 0 1.36.09 2.01.27 1.53-1.04 2.2-.82 2.2-.82.44 1.09.16 1.9.08 2.1.51.56.82 1.27.82 2.15 0 3.09-1.87 3.76-3.65 3.96.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.19 0 .21.15.46.55.38A8.001 8.001 0 0016 8c0-4.42-3.58-8-8-8z" />
        </svg>
      </a>
      <a
     className={isLaunchWeek ? css.launchWeek : useDark && !isLaunchWeek ? css.ghLinkDark : css.ghLink}
        href={`https://github.com/${user}/${repo}/stargazers`}
        target="_blank"
      >
        <span>
        {githubStars >= 1000
            ? (githubStars / 1000).toFixed(1) + 'k'
            : githubStars}
        </span>
      </a>
    </div>
  )
}

export default GithubButton


